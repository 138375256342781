import {
  Button,
  Input,
  Radio,
  RadioGroup,
  Select,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Stack,
} from '@chakra-ui/react';
import {
  convertFromRaw,
  convertToRaw,
  DraftEditorCommand,
  Editor,
  EditorState,
  RichUtils,
} from 'draft-js';
import { draftjsToMd, mdToDraftjs } from 'draftjs-md-converter';
import * as mammoth from 'mammoth/mammoth.browser.js';
import React, { ChangeEvent, FC, ReactNode, useEffect, useState } from 'react';
import TurndownService from 'turndown';
import tw, { css, styled } from 'twin.macro';
import { LiveTextPoem } from '~/components/live-text-poem/live-text-poem';
import {
  engineMetadataPattern,
  engineSplitMetadataPattern,
  Frontmatter,
  isSSG,
  LocalStorageKey,
} from '~/config';
import { readFileInput, storeItem } from '~/utils';
import { getStoredItem } from '../utils/storage';

// don't add export - uses local gatsby plugin
// eslint-disable-next-line
const frontmatter: Frontmatter = {
  title: 'Redaction Engine: Copy/Paste Edition',
  order: 10,
  screenShoot: false,
};

const displayWidths = [
  { label: 'Desktop (700)', value: tw`[width:700px] md:[width:700px]` },
  {
    label: 'Mobile - iPhone 12/13 (390)',
    value: tw`[width:390px] md:[width:390px]`,
  },
  { label: '-- other --', value: tw`[width:390px] md:[width:390px]` },
  { label: 'Pixel (412)', value: tw`[width:412px] md:[width:412px]` },
  { label: 'iPhone X/11 (375)', value: tw`[width:375px] md:[width:375px]` },
  { label: 'Galaxy S, LG G6 (360)', value: tw`[width:360px] md:[width:360px]` },
];

const mdToDraftOptions = {
  inlineStyles: {
    Strong: {
      type: 'BOLD',
      symbol: '**',
    },
  },
};

const draftToMdOptions = {
  BOLD: '**',
};

type ViewState = 'redacted' | 'animated' | 'unredacted';

const Page: FC = () => {
  const stored = getStoredItem(LocalStorageKey.redactEngine);

  const [editorState, setEditorState] = useState(() =>
    stored?.mdContent
      ? EditorState.createWithContent(
          convertFromRaw(
            mdToDraftjs(
              stored.mdContent.replace(engineMetadataPattern, ''),
              mdToDraftOptions,
            ),
          ),
        )
      : EditorState.createEmpty(),
  );

  const editor = React.useRef<Editor | null>(null);

  // placment for use in constructMetadata
  const [condenseDesk, setCondenseDesk] = useState<number>(
    stored?.condenseDesk ?? 50,
  );

  // placment for use in constructMetadata
  const [condenseMobile, setCondenseMobile] = useState<number>(
    stored?.condenseMobile ?? 75,
  );

  // placment for use in mdContent state
  const constructMetadata = (desk?: number, mobile?: number) =>
    `metadata -- desk:${desk ?? condenseDesk} mobile: ${
      mobile ?? condenseMobile
    }\n`;

  // placment for use in mdContent state
  const getMdContentWithMetadata = (val?: string, metadata?: string) => {
    let meta =
      metadata || val?.match(engineMetadataPattern)?.[0] || constructMetadata();

    let text = val?.replace(engineMetadataPattern, '') ?? '';

    return { metadata: meta, text, all: meta + text };
  };

  /** Set using `setMdContentWithMetadata` */
  const [mdContent, _internal_setMdContent] = useState(
    getMdContentWithMetadata(stored?.mdContent)?.all,
  );

  const [viewState, setViewState] = useState<ViewState>(
    stored?.viewState ?? 'redacted',
  );

  const [selectedWidth, setSelectedWidth] = useState<number>(
    stored?.selectedWidth ?? 0,
  );

  useEffect(() => {
    storeItem(LocalStorageKey.redactEngine, {
      viewState,
      selectedWidth,
      condenseDesk,
      condenseMobile,
      mdContent,
    });
  }, [condenseDesk, condenseMobile, viewState, selectedWidth, mdContent]);

  useEffect(() => {
    editor.current?.focus();
  }, []);

  const setMdContentWithMetadata = (val: string, metadata?: string) => {
    _internal_setMdContent(getMdContentWithMetadata(val, metadata).all);
  };

  const handleCondenseDeskChange = (val: number) => {
    setCondenseDesk(val);

    // auto-switch view to desktop on slider change
    if (selectedWidth !== 0) {
      setSelectedWidth(0);
    }
    setMdContentWithMetadata(mdContent, constructMetadata(val, undefined));
  };

  const handleCondenseMobileChange = (val: number) => {
    setCondenseMobile(val);

    // auto-switch view to mobile on slider change
    if (selectedWidth === 0) {
      setSelectedWidth(1);
    }
    setMdContentWithMetadata(mdContent, constructMetadata(undefined, val));
  };

  const handleDisplayChange = (e) => {
    setSelectedWidth(e.target.selectedIndex);
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      parse(e.target.files[0]);
    }
  };

  const parse = async (file: File) => {
    const arrayBuffer = await readFileInput(file);
    const result = await mammoth.convertToHtml({ arrayBuffer });
    const sanitizedResult = result.value
      // removes errant comma at start of unredacted block
      // (can't really do end since that might be intentional)
      .replace(/<strong>\W\s?/g, '<strong>')
      // strips out garbage
      .replace(/[\[\]\\]/g, '');

    let parsed = new TurndownService({ linkStyle: 'referenced' })
      .keep(['strong'])
      .remove(['em'])
      .turndown(sanitizedResult);

    parsed = parsed
      .replace(/^[\s\S]*\*\*\n\n/m, '') // remove title block
      .replace(/\n\n\[1\][\s\S]*/m, '') // remove referenced links block
      .replace(/\[.*?\]/g, '') // remove inline links, since remove('a') didn't work
      .replace(/_/g, '') // remove('em') not working during parse (?)
      .replace(/\!\(data:.*?\)/g, '') // remove images
      .replace(/\n\n?/g, '') // remove breaks
      .replace(/,\s*?,/g, ',');

    setMdContentWithMetadata(parsed);

    /* Convert the simplified md into the editor so all we get is bold tags,
            whereas convertFromHTML gives us the the whole word doc */
    setEditorState(
      EditorState.createWithContent(
        convertFromRaw(mdToDraftjs(parsed, mdToDraftOptions)),
      ),
    );
  };

  function focusEditor() {
    editor.current?.focus();
  }

  const handleEditorStateChange = (state: EditorState) => {
    const md = draftjsToMd(
      convertToRaw(state.getCurrentContent()),
      draftToMdOptions,
    );

    // handle paste of CMS md with metadata
    // (the editor has handlePastedText but there was a race cond w/this handler)
    if (engineMetadataPattern.test(md)) {
      const { metadata, text } = getMdContentWithMetadata(md);
      const metadataParts = metadata
        .match(engineSplitMetadataPattern)
        ?.slice(1);
      if (metadataParts) {
        const deskIndex = metadataParts?.findIndex((p) => p.includes('desk'));
        if (deskIndex > -1) {
          const deskVal = parseFloat(metadataParts[deskIndex + 1]);
          setCondenseDesk(deskVal);
        }
        const mobileIndex = metadataParts?.findIndex((p) =>
          p.includes('mobile'),
        );
        if (mobileIndex > -1) {
          const mobileVal = parseFloat(metadataParts[mobileIndex + 1]);
          setCondenseMobile(mobileVal);
        }
      }
      setEditorState(
        EditorState.createWithContent(
          convertFromRaw(mdToDraftjs(text, mdToDraftOptions)),
        ),
      );
    }
    // handle normal change case
    else {
      setEditorState(state);
    }

    setMdContentWithMetadata(md); // triggers local storage save via effect
  };

  const handleKeyCommand = (
    command: DraftEditorCommand,
    editorState: EditorState,
  ) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      handleEditorStateChange(newState);
      return 'handled';
    }

    return 'not-handled';
  };

  const handleBoldClick = () => {
    handleEditorStateChange(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
  };

  const handleCopyClick = () => {
    !isSSG && navigator.clipboard.writeText(mdContent);
  };

  const isDesktopSelected = selectedWidth === 0;

  return (
    <div tw="w-screen h-screen bg-white grid [grid-template-columns:3fr 7fr]">
      <div tw="h-screen py-20 px-4">
        <FileControlsHeader>
          <Input
            type="file"
            multiple={false}
            accept=".docx"
            onChange={onFileChange}
            tw="text-sm mb-4 px-0 border-none"
          />
          <Button onClick={handleCopyClick}>Copy for CMS</Button>
        </FileControlsHeader>

        <Button onClick={handleBoldClick} tw="text-sm mb-6">
          Bold
        </Button>

        {/* eslint-disable-next-line */}
        <div
          tw="border-gray-600 [height:70vh] overflow-y-scroll cursor-text"
          onClick={focusEditor}
        >
          <Editor
            ref={editor}
            editorState={editorState}
            onChange={handleEditorStateChange}
            handleKeyCommand={handleKeyCommand}
          />
        </div>
      </div>

      <div
        css={[
          tw`overflow-y-scroll grid justify-items-center px-5 w-full md:(px-0)`,
          isDesktopSelected ? tw`bg-black` : tw`bg-gray-800`,
        ]}
      >
        <div tw="text-white text-2xl text-center w-full p-10">
          <h1 tw="text-xl text-center">Redaction Preview Engine</h1>
          <p tw="text-sm mb-3">
            When finished, copy & paste your text into the cms
          </p>

          <div tw="flex items-center justify-around text-sm font-tertiary">
            <RadioGroup
              onChange={(val: ViewState) => setViewState(val)}
              value={viewState}
            >
              <Stack direction="row" spacing={5}>
                <Radio value="redacted">After</Radio>
                <Radio value="unredacted">Before</Radio>
                <Radio value="animated">Animate</Radio>
              </Stack>
            </RadioGroup>

            <div tw="flex gap-5 items-center">
              Condense By:
              <div tw="flex flex-col w-28">
                <Slider
                  value={condenseDesk}
                  onChange={handleCondenseDeskChange}
                  max={100}
                  step={1}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
                <div>desktop {condenseDesk}%</div>
              </div>
              <div tw="flex flex-col w-28">
                <Slider
                  value={condenseMobile}
                  onChange={handleCondenseMobileChange}
                  max={100}
                  step={1}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
                <div>mobile {condenseMobile}%</div>
              </div>
            </div>

            <Select
              id="display"
              value={displayWidths[selectedWidth].label}
              onChange={handleDisplayChange}
              width="auto"
            >
              {displayWidths.reduce(
                (prev, cur) => [
                  ...prev,
                  <option key={cur.label} value={cur.label} tw="text-black">
                    {cur.label}
                  </option>,
                ],
                [] as ReactNode[],
              )}
            </Select>
          </div>
        </div>
        <LiveTextPoem
          noAnimation={viewState !== 'animated'}
          noRedaction={viewState === 'unredacted'}
          simulateMobile={!isDesktopSelected}
          condenseByPctDesktop={condenseDesk * 0.01}
          condenseByPctMobile={condenseMobile * 0.01}
          poemMarkdown={mdContent}
          customCss={
            !isDesktopSelected && [
              displayWidths[selectedWidth].value,
              tw`px-10 py-0.5 bg-black`,
            ]
          }
        />
      </div>
    </div>
  );
};

export default Page;

const FileControlsHeader = styled.div(() => [
  css`
    display: flex;
    justify-content: space-between;
    input[type='file']::file-selector-button {
      border: none;
      padding: 0.5rem;
      border-radius: 0.5rem;
      background-color: #c7cbd0;
      transition: 0.2s;
      font-weight: bold;
      margin-right: 0.5rem;
    }

    input[type='file']::file-selector-button:hover {
      background-color: #a0a0a0;
    }
  `,
]);
